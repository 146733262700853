import Swiper from 'swiper';
import { Navigation, EffectFade, Keyboard } from 'swiper/modules';

export default function FlyoverCarousel(el, {
	swiperClass,
	actions,
    events,
	markup,
}) {
	const slides = el.querySelector(`.${swiperClass}`);
	const prev = el.querySelector('.flyover-carousel__nav-prev');
	const next = el.querySelector('.flyover-carousel__nav-next');

	const videos = document.querySelectorAll('.video');
	const progressBarWrappers = document.querySelectorAll('.progress');
	const progressBars = document.querySelectorAll('.progress-bar');
	const progressNodes = document.querySelectorAll('.progress-node');
	const pulseNodes = document.querySelectorAll('.pulse-node');

	const launchVideoBtns = document.querySelectorAll('.flyover-carousel__launch-vid-btn');

	const swiper = new Swiper(slides, {
		modules: [
			Navigation,
			EffectFade,
			Keyboard,
		],
		navigation: {
            prevEl: prev,
            nextEl: next,
        },
		effect: 'fade',
		fadeEffect: {
			crossFade: false,
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
	});

	videos.forEach((video, index) => {
		video.addEventListener('timeupdate', function() {
			const percentage = (video.currentTime / video.duration) * 100;
			progressBars[index].style.width = `${percentage}%`;
			progressNodes[index].style.left = `${percentage}%`;

			if (percentage === 100 && index < videos.length - 1) {
				pulseNodes[index + 1].classList.add('active');
			} else if (percentage !== 100 && index !== 0) {
				pulseNodes[index].classList.remove('active');
			}
		});
	});

	swiper.on('slideChange', (e) => {
		const video = e.slides[e.activeIndex].querySelector('video');
		const prevVideo = e.slides[e.previousIndex].querySelector('video');

		progressBarWrappers.forEach((bar, index) => {
			index === e.activeIndex - 1 ? bar.classList.add('active') : bar.classList.remove('active');
		});

		if (e.activeIndex > e.previousIndex) {
			prevVideo.currentTime = prevVideo.duration;
		} else {
			prevVideo.currentTime = 0;
			prevVideo.pause();
			pulseNodes.forEach(node => node.classList.remove('active'));
		}

		video && video.play();

		if (e.activeIndex === 0) {
			progressBarWrappers[0].classList.add('active');
			pulseNodes[0].classList.add('active');
		} else {
			pulseNodes[0].classList.remove('active');
		}
	});

	launchVideoBtns.forEach((btn) => {
		btn.addEventListener('click', (e) => {
			const vidMarkup = e.target.dataset.markup;
			events.emit(actions.loadModal, { markup: vidMarkup });
		});
	});
}