import YouTubePlayer from 'youtube-player';

export default function WatchVideoBtn(el, {
	actions,
    events,
	markup,
	videoId,
	videoPlayer,
}) {
	el.addEventListener('click', () => {
		events.emit(actions.loadModal, { markup: markup });
		
		if (videoPlayer.value === 'youtube') {
			const player = document.getElementById('youtube-player');

			const youtubePlayer = YouTubePlayer(player, {
				videoId: videoId,
				playerVars: {
					autoplay: 1,
				},
			});

			events.on(actions.closeModal, () => youtubePlayer.pauseVideo());
		}

		if (videoPlayer.value === 'vimeo') {
			const cont = document.getElementById('vimeo-player');
			
			playerVimeo = new Vimeo.Player(cont, { // eslint-disable-line no-undef
                id: videoId, autoplay: 1, mute: 1, loop: 1,
            });

			events.on(actions.closeModal, () => playerVimeo.pause());
		}
	});
}
