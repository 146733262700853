export default function CtaTab(el, {
	activeClass,
}) {
	const hero = document.querySelector('.hero');
	const footer = document.querySelector('.footer');
	
	const obCallback = (payload) => {
		payload.forEach((e) => {
			if (e.target.className.includes('hero')) {
				if (e.isIntersecting) {
					el.classList.remove(activeClass);
				} else {
					el.classList.add(activeClass);
				}
			}

			if (e.target.className === 'footer') {
				if (e.isIntersecting) {
					el.classList.remove(activeClass);
				} else {
					el.classList.add(activeClass);
				}
			}
		});
	}

	const heroObserver = new IntersectionObserver(obCallback);
	const footerObserver = new IntersectionObserver(obCallback);

	heroObserver.observe(hero);
	footerObserver.observe(footer);
}