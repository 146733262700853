export default function ExpandingDetails(el, {
	desktopClass,
	mobileClass,
}) {
	const expandingDetailsDesktop = el.querySelector(`.${desktopClass}`);
	const assets = expandingDetailsDesktop.querySelectorAll('.expanding-details__asset');
	const defaultCardDescription = expandingDetailsDesktop.querySelector('.expanding-details__description[data-description="0"]');
	const cardDescriptionWrapper = expandingDetailsDesktop.querySelector('.expanding-details__description-wrapper');
	const cardTitles = expandingDetailsDesktop.querySelectorAll('.expanding-details__title');
	const cardDescriptions = expandingDetailsDesktop.querySelectorAll('.expanding-details__description');

	const expandingDetailsMobile = el.querySelector(`.${mobileClass}`);
	const assetsMobile = expandingDetailsMobile.querySelectorAll('.expanding-details__asset');
	
	cardDescriptionWrapper.style.height = `${defaultCardDescription.getBoundingClientRect().height}px`;
	
	const toggleCards = (e) => {
		if (e.classList.contains('active')) {
			assets.forEach(card => card.classList.remove('active'));
			cardTitles.forEach(card => card.classList.remove('active'));
			cardDescriptions.forEach(card => card.classList.remove('active'));
			defaultCardDescription.classList.add('active');
			cardDescriptionWrapper.style.height = `${defaultCardDescription.getBoundingClientRect().height}px`;
			cardDescriptionWrapper.style.marginTop = '0';
			return;
		}
		
		const cardIndex = e.dataset.assetIndex;
		
		assets.forEach(card => card.classList.remove('active'));
		cardTitles.forEach(card => card.classList.remove('active'));
		cardDescriptions.forEach(card => card.classList.remove('active'));

		e.classList.toggle('active');
		cardTitles[cardIndex].classList.add('active');
		cardDescriptions[cardIndex].classList.add('active');
		
		const detailsHeight = cardDescriptions[cardIndex].getBoundingClientRect().height;
		const titleHeight = cardTitles[cardIndex].getBoundingClientRect().height + 30;
		cardDescriptionWrapper.style.height = `${detailsHeight}px`;
		cardDescriptionWrapper.style.marginTop = `${titleHeight}px`;
	}
	
	assets.forEach((asset) => {
		asset.addEventListener('click', (e) => {
			toggleCards(e.target);
		});
	});

	assetsMobile.forEach(asset => asset.addEventListener('click', e => e.target.classList.toggle('active')));
}